import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { auth } from '../utils/firebaseConfig';
import { useTheme } from '../themes';
import Footer from '../components/Footer';

const Pricing = () => {
  const navigate = useNavigate();
  const [subscription, setSubscription] = useState(() => {
    const savedSubscription = localStorage.getItem('subscription');
    return savedSubscription ? JSON.parse(savedSubscription) : null;
  });

  const [isAnnual, setIsAnnual] = useState(false); // Switch para alternar entre mensual y anual
  const { isDarkTheme } = useTheme();

  useEffect(() => {
    const fetchSubscription = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const token = await user.getIdToken();
          const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (response.ok) {
            const data = await response.json();
            setSubscription(data.activeSubscription);
            localStorage.setItem('subscription', JSON.stringify(data.activeSubscription));
          }
        } catch (error) {
          console.error('Error fetching subscription:', error);
        }
      }
    };

    fetchSubscription();
  }, []);

  const handlePurchase = (planId) => {
    navigate(`/payment/${planId}`);
  };

  const renderButton = (planId, planPrice) => {
    if (subscription && subscription.isActive) {
      // Verificar si el plan anual o mensual está activo
      if (subscription.plan.planId === 'annual_unlimited' || subscription.plan.planId === 'monthly_unlimited') {
        return <Button variant="success" disabled>Lo Tienes</Button>;
      }
      return <Button variant="primary" onClick={() => handlePurchase(planId)}>Change to {planId}</Button>;
    }
    return <Button variant="primary" onClick={() => handlePurchase(planId)}>Shop now {isAnnual ? 'Annual' : 'Monthly'}</Button>;
  };

  const planPrice = isAnnual ? 180 : 20; // Cambia el precio según el estado del switch
  //const planName = isAnnual ? 'Annual' : 'Monthly';
  const planId = isAnnual ? 'annual_unlimited' : 'monthly_unlimited';

  return (
    <>
      <Container className="mt-5">
        <Row style={{ justifyContent: 'center' }}>
          <Col xs={12} md={12} lg={8} className="">
            <h1 className="text-center">Plans</h1>
            <p className="text-center">Choose between monthly or annual subscription options, with the flexibility to upgrade at any time.</p>
          </Col>
        </Row>

        <Row className="mt-4 " style={{ justifyContent: 'center' }}>
          <Col md={4} className="mb-4">
            <Card className={`h-100 ${isDarkTheme ? 'dark-theme' : 'light-theme'}`}>
              <Card.Header className="text-center">
                <h1 className="mb-0">Free</h1>
              </Card.Header>
              <Container>
              <Button  variant="primary" disabled>Plan Free</Button>

              </Container>
              <Card.Body >
              <hr></hr>

                <Table className={` ${isDarkTheme ? 'dark-theme' : 'light-theme'}`} bordered>
                  <tbody>
                    <tr>
                      <td>✓ High Resolution PNG</td>
                    </tr>
                    <tr>
                      <td>✓ Limited Downloads</td>
                    </tr>
                    <tr>
                      <td>✓ Personal License</td>
                    </tr>

                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>

          <Col md={4} className="mb-4">
            <Card className={`h-100 ${isDarkTheme ? 'dark-theme' : 'light-theme'}`}>
            <Card.Header className="text-center d-flex align-items-center" style={{ justifyContent: 'space-between' }}>
                <div className="d-flex flex-column text-start">
                  <div className="d-flex text-start align-items-center">
                    {/* Mostramos el precio prorrateado si es anual, sino el precio mensual */}
                    <h1 className="mb-0">${isAnnual ? (180 / 12).toFixed(0) : planPrice} </h1> 
                    <h5 className="mt-3">/month</h5>
                      {isAnnual && <p className="text-success mt-2 p-2" style={{ fontWeight: '800' }}> Save 25%</p>}
                  </div>
                  
                </div>
                
                          <div className="custom-switch-container">
            <div className="d-flex align-items-center">
              <label className="switch-label me-2">{isAnnual ? 'Monthly' : 'Annual'}</label>
              <label className="custom-switch">
                <input
                  type="checkbox"
                  checked={isAnnual}
                  onChange={() => setIsAnnual(!isAnnual)}
                />
                <span className="slider"></span>
                
              </label>
            </div>
          </div>

              </Card.Header>
              <Container>
                
              {renderButton(planId, planPrice)}

              </Container>
              <Card.Body>
              <hr></hr>

                <Table bordered className={` ${isDarkTheme ? 'dark-theme' : 'light-theme'}`} >
                  <tbody>
                    <tr>
                      <td>✓ High Resolution PNG</td>
                    </tr>
                    <tr>
                      <td>✓ Unlimited Downloads</td>
                    </tr>
                    <tr>
                      <td>✓ Access all our Resources</td>
                    </tr>
                    <tr>
                      <td>✓ Original Files (SVG, HTML, CSS, JS, Figma - others)</td>
                    </tr>
                    <tr>
                      <td>✓ Early Access to new resources</td>
                    </tr>
                    <tr>
                      <td>✓ Commercial License</td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>

      {/* FAQ Section */}
      <Row xs={12} md={12} lg={8} className="mt-5 py-5">
          <Col>
            <h2>FAQ</h2>
            <hr />
            <Row>
              <Col>
                <h5>Which Plan Do I Need?</h5>
                <p>Choose the plan that fits your needs. The Free plan is great for basic use, while Monthly and Annual plans offer additional benefits for more extensive use.</p>
              </Col>
              <Col>
                <h5>What is Custom Illustrations?</h5>
                <p>Custom Illustrations are available in the Monthly and Annual plans, providing personalized content to suit your needs. Contact us for more insight info@cratepic.com</p>
              </Col>
            </Row>
            <br>
            </br>
          <hr></hr>
          <br>
          </br>
            <Row>
              <Col>
                <h5>How Can I Use Cratepic?
                </h5>
                <p>With supporting text below as a natural lead-in to additional content.</p>
              </Col>
              <Col>
                <h5>Can I Download Unlimited?</h5>
                <p>Custom Illustrations are available in the Monthly and Annual plans, providing personalized content to suit your needs. </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Pricing;
