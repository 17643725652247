import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { auth, googleProvider, signInWithPopup, signInWithEmailAndPassword } from '../utils/firebaseConfig';
import { Row, Col, Form, Button, Container } from 'react-bootstrap';
import {  useTheme } from '../themes';

const plan = 'free'; // Puedes hacer que esto sea dinámico si es necesario

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const API_BASE_URL = process.env.REACT_APP_API_URL;
  const { isDarkTheme } = useTheme();

  useEffect(() => {
    googleProvider.setCustomParameters({
      prompt: 'select_account',
    });
  }, []);

  const from = location.state?.from?.pathname || '/';

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      const user = auth.currentUser;
      const response = await fetch(`${API_BASE_URL}/api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          uid: user.uid,
          email: user.email,
        }),
      });

      if (!response.ok) {
        throw new Error('Error al enviar datos al backend');
      }

      const pendingAction = JSON.parse(localStorage.getItem('pendingAction'));
      if (pendingAction) {
        const { action, productId } = pendingAction;
        localStorage.removeItem('pendingAction');
        navigate(`/${action}/${productId}`);
      } else {
        navigate(from, { replace: true });
      }
    } catch (error) {
      console.error('Error logging in:', error);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      const idToken = await user.getIdToken();
      const response = await fetch(`${API_BASE_URL}/api/google-register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ idToken, plan }),
      });

      if (!response.ok) {
        throw new Error('Error al enviar datos al backend');
      }

      const pendingAction = JSON.parse(localStorage.getItem('pendingAction'));
      if (pendingAction) {
        const { action, productId } = pendingAction;
        localStorage.removeItem('pendingAction');
        navigate(`/${action}/${productId}`);
      } else {
        navigate(from, { replace: true });
      }
    } catch (error) {
      console.error('Error logging in with Google:', error);
    }
  };

  return (
    <Container fluid className='login-container' style={{position:"absolute"}}>
      <Row className={`${isDarkTheme ? 'dark-theme' : 'light-theme' }`}  >
        <Col md={8} className="login-image login-image d-flex align-items-center  text-center p-5 justify-content-center" style={{backgroundColor: "#ECE6F2"}}>
          {/* Aquí puedes poner la imagen */}
          <div className="image-wrapper">
            <img
              src="https://res.cloudinary.com/djlafescs/image/upload/v1726450460/Business/PNG/Bar_Char_for_Data_Analysis"
              alt="Login"
              className="img-fluid"
            />
          </div>
        </Col>
        <Col md={4} className="login-form p-5" style={{maxWidth:"480px"}}>
          <h3 className="text-center my-4">Bienvenido nuevamente</h3>
          <p className="text-center">Ingresa para continuar</p>
          <Form >
            <Form.Group controlId="formBasicEmail">
              <Form.Label  onSubmit={handleLogin}>Email</Form.Label>
              <Form.Control
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className='border-input'
              />
            </Form.Group>
            <Form.Group controlId="formBasicPassword" className="mt-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className='border-input'

              />
            </Form.Group>
            <br></br>
            <Button variant="primary" type="submit" className={`mt-3 ${isDarkTheme ? 'dark-theme' : 'light-theme'}`}>
              Login
            </Button>
          </Form>
          <div className="mt-3   align-items-center">
            <p className='text-center'>También puedes ingresar con:</p>
            <Button variant='secondary' className={`mt-3 ${isDarkTheme ? 'dark-theme' : 'light-theme'}`} onClick={handleGoogleLogin}>
            <img
              src="Google__G__logo.svg.webp"
              alt="Login"
              className="img-fluid mx-2"
              style={{maxWidth: '17px'}}
            />
              Google
            </Button>
          </div>
          <p className="mt-3 text-center">
            ¿No tienes cuenta? <Link to="/register">Regístrate</Link>
          </p>
          <p className="mt-3 text-center">
          <Link to="/reset-password">¿Olvidaste tu contraseña?</Link>
        </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
