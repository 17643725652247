import React from 'react';
import { Container, Card } from 'react-bootstrap';
import Footer from '../components/Footer';

const WhatsNew = () => {
  return (
    <div className="hww">
      <br /><br /><br /><br />
      <Container>
        <div className="steps text-center py-3 pt-md-5 pb-md-4">
          <h1>What's New?</h1>
          <p>
            Quickly build an effective pricing table for your potential customers with this Bootstrap example. It's built with default Bootstrap components and utilities with little customization.
          </p>
        </div>


      <Card className="card_news justify-content-center">
        <Card.Header as="h5">11/01/2022 - Launch Cratepic</Card.Header>
        <Card.Body>
          <Card.Title>Title Feature</Card.Title>
          <Card.Text>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas molestie turpis a congue sodales.
          </Card.Text>
        </Card.Body>
      </Card>
    <br></br>
      <Card className="card_news justify-content-center">
        <Card.Header as="h5">22/01/2022 - Sports Category</Card.Header>
        <Card.Body>
          <Card.Title>Title Feature</Card.Title>
          <Card.Text>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas molestie turpis a congue sodales.
          </Card.Text>
        </Card.Body>
      </Card>
      <br></br>

      <Card className="card_news justify-content-center">
        <Card.Header as="h5">11/02/2022 - New Feature</Card.Header>
        <Card.Body>
          <Card.Title>Title Feature</Card.Title>
          <Card.Text>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas molestie turpis a congue sodales.
          </Card.Text>
        </Card.Body>
      </Card>
      </Container>
      <br /><br />
      <Footer></Footer>

    </div>
  );
};

export default WhatsNew;
