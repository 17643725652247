import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { auth } from '../utils/firebaseConfig';
import { Container, Row, Col, Card, Button, Breadcrumb, Image, Spinner } from 'react-bootstrap';
import Footer from '../components/Footer';

const Payment = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [item, setItem] = useState(null);
  const [referenceCode, setReferenceCode] = useState('');
  const [signature, setSignature] = useState('');
  const [isSubscription, setIsSubscription] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const user = auth.currentUser;
    if (!user) {
      navigate('/login');
      return;
    }

    const fetchItem = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/products/${id}`);
        if (!response.ok) {
          throw new Error(`Network response was not ok, status: ${response.status}`);
        }
        const itemData = await response.json();
        setItem(itemData);
        return itemData;
      } catch (error) {
        console.error('Error fetching item:', error);
        alert('There was an issue fetching the item details. Please try again later.');
        navigate('/products');
      } finally {
        setLoading(false);
      }
    };

    const initializePayment = async () => {
      const itemData = await fetchItem();
      const referenceCode = `${id}`;  // Generate reference code using only product or subscription id

      setReferenceCode(referenceCode);

      if (itemData) {
        try {
          const token = await user.getIdToken();
          const response = await fetch(`${process.env.REACT_APP_API_URL}/api/generate-signature`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
              referenceCode: referenceCode,
              amount: itemData.price,
              currency: 'USD',
            }),
          });
          if (!response.ok) throw new Error('Network response was not ok');
          const data = await response.json();
          setSignature(data.signature);
        } catch (error) {
          console.error('Error generating signature:', error);
        }
      }
    };

    setIsSubscription(id === 'monthly_unlimited' || id === 'annual_unlimited');
    initializePayment();
  }, [id, navigate]);

  useEffect(() => {
    const handleConfirmation = () => {
      const queryParams = new URLSearchParams(window.location.search);
      const paymentStatus = queryParams.get('message');
      const referenceCode = queryParams.get('referenceCode');

      if (paymentStatus === 'APPROVED' && item) {
        navigate('/thankyoupage', {
          state: { 
            title: item.title, 
            description: item.description, 
            price: item.price, 
            buyerEmail: auth.currentUser.email,
            downloadUrl: item.publicDownloadUrl 
          }
        });
      }
    };

    handleConfirmation();
  }, [item, navigate]);

  if (!auth.currentUser) return <p>Redirecting to login...</p>;

  if (loading) return (
    <Container className="mt-5 mb-5" style={{ maxWidth: '768px', textAlign: 'center' }}>
      <Spinner animation="border" variant="primary" />
      <p>Loading item details...</p>
    </Container>
  );

  return (
    <>
      <Container className="mt-5 mb-5" style={{ maxWidth: '768px' }}>
        <Breadcrumb>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          {item && item.title ? (
            <Breadcrumb.Item href={`/product/${id}`}>{item.title}</Breadcrumb.Item>
          ) : isSubscription ? (
            <Breadcrumb.Item href="/pricing">
              {id === 'monthly_unlimited' ? 'Monthly Subscription' : 'Annual Subscription'}
            </Breadcrumb.Item>
          ) : (
            <Breadcrumb.Item active>Loading...</Breadcrumb.Item>
          )}
          <Breadcrumb.Item active>Payment</Breadcrumb.Item>
        </Breadcrumb>

        <Row>
          <Col>
            <Card className="p-4 custom-card">
              <Card.Header className="text-center">
                <Image src={item.publicDownloadUrl} alt={item.title} fluid />
              </Card.Header>
              <Card.Body>
                <h1 className="mb-4">Make a Payment</h1>
                <Card.Text><strong>Item Name: </strong> {item.title || item.planId}</Card.Text>
                <Card.Text><strong>Description:</strong> {item.description}</Card.Text>
                <Card.Text><strong>Price:</strong> ${item.price}</Card.Text>
                <Card.Text><strong>Buyer Email:</strong> {auth.currentUser.email}</Card.Text>

                <form method="post" action="https://checkout.payulatam.com/ppp-web-gateway-payu/">
                <input name="merchantId" type="hidden" value={process.env.REACT_APP_PAYU_MERCHANT_ID} />
                <input name="accountId" type="hidden" value={process.env.REACT_APP_PAYU_ACCOUNT_ID} />
                <input name="description" type="hidden" value={item.description} />
                <input name="referenceCode" type="hidden" value={referenceCode} />
                <input name="amount" type="hidden" value={item.price} />
                <input name="tax" type="hidden" value={(item.price * 0.17).toFixed(2)} />
                <input name="taxReturnBase" type="hidden" value={(item.price * 0.83).toFixed(2)} />
                <input name="currency" type="hidden" value="USD" />
                <input name="signature" type="hidden" value={signature} />
                <input name="test" type="hidden" value="0" />
                <input name="buyerEmail" type="hidden" value={auth.currentUser.email} />
                <input name="responseUrl" type="hidden" value={`${process.env.REACT_APP_API_URL}/thankyoupage`} />
                <input name="confirmationUrl" type="hidden" value={`${process.env.REACT_APP_API_URL}/api/webhook/payu`} />
                <Button type="submit" variant="primary" className="custom-button">Pay</Button>
              </form>

              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Payment;
