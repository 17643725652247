import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { auth, googleProvider, createUserWithEmailAndPassword, signInWithPopup } from '../utils/firebaseConfig';
import { Row, Col, Form, Button, Container, Alert } from 'react-bootstrap';
import {  useTheme } from '../themes';
const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const API_BASE_URL = process.env.REACT_APP_API_URL;
  const plan = 'free';
  const { isDarkTheme } = useTheme();
  useEffect(() => {
    googleProvider.setCustomParameters({
      prompt: 'select_account',
    });
  }, []);

  const from = location.state?.from?.pathname || '/';

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      const response = await fetch(`${API_BASE_URL}/api/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          uid: user.uid,
          email: user.email,
          password,
          plan,
        }),
      });

      if (!response.ok) {
        throw new Error('Error al registrar el usuario en el backend');
      }

      navigate(from, { replace: true });
    } catch (error) {
      setError(error.message || 'Error al registrar el usuario');
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleRegister = async () => {
    setLoading(true);
    setError('');
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      const idToken = await user.getIdToken();
      const response = await fetch(`${API_BASE_URL}/api/google-register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ idToken, plan }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Error al registrar el usuario en el backend');
      }

      navigate(from, { replace: true });
    } catch (error) {
      setError(error.message || 'Error al registrar con Google');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Container fluid className="login-container "style={{position:"absolute"}}>
      <Row >
        <Col md={8} className="login-image d-flex align-items-center  text-center p-5 justify-content-center"  style={{backgroundColor: "#ECE6F2"}}>
          {/* Aquí puedes poner la imagen */}
          <div className="image-wrapper">
            <img
              src="https://res.cloudinary.com/djlafescs/image/upload/v1726450463/Business/PNG/Rocket_Launch_Data_Visualization.png"
              alt="Register"
              className="img-fluid text-center "
            />
          </div>
        </Col>
        <Col md={4} className="login-form p-5" style={{maxWidth:"480px"}}>
          <h3 className="text-center my-4">Bienvenido</h3>
          <p className="text-center">Regístrate para continuar</p>
          <br></br>

          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleRegister}>

            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className='border-input'

              />
            </Form.Group>
            <Form.Group controlId="formBasicPassword" className="mt-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className='border-input'

              />
            </Form.Group>
            <br></br>
            <Button variant="primary" type="submit" className="mt-3" disabled={loading}>
              {loading ? 'Registrando...' : 'Register'}
            </Button>
          </Form>
          <div className="mt-3 w-100" >
            <Button variant='secondary'  className={` ${isDarkTheme ? 'dark-theme' : 'light-theme'}`} onClick={handleGoogleRegister} disabled={loading}>
            <img
              src="Google__G__logo.svg.webp"
              alt="Login"
              className="img-fluid mx-2"
              style={{maxWidth: '17px'}}
            />
              {loading ? 'Registrando...' : 'Register with Google' }
            </Button>
          </div>
          <p className="mt-3 text-center">
            ¿Ya tienes una cuenta? <Link to="/login">Inicia sesión aquí</Link>
          </p>

        </Col>
      </Row>
    </Container>
    </div>

  );
};

export default Register;
