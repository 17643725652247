import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Footer from '../components/Footer';

const HowWeWork = () => {
  return (
    <div className="hww">
      <br /><br /><br /><br />
      <Container >
        <div className="steps text-center py-3 pt-md-5 pb-md-4">
          <h1>How we work?</h1>
          <p>
            Quickly build an effective pricing table for your potential customers with this Bootstrap example. It's built with default Bootstrap components and utilities with little customization.
          </p>
        </div>
        <br /><br /><br /><br />
        <Row className="steps mb-2">
          <Col md={6} >
            <h4>Step 1</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas molestie turpis a congue sodales.</p>
          </Col>
          <Col md={6} className="d-flex">
            <img src="https://res.cloudinary.com/djlafescs/image/upload/v1726450463/Business/PNG/Rocket_Launch_Data_Visualization.png" alt="Step 1" className="img-fluid" />
          </Col>
        </Row>
        <br /><br /><br /><br />
        <Row className="steps mb-2">
          <Col md={6} className="d-flex">
            <img src="https://res.cloudinary.com/djlafescs/image/upload/v1726450461/Business/PNG/People_Comparing_Stats_1.jpg" alt="Step 2" className="img-fluid" />
          </Col>
          <Col md={6}>
            <h4>Step 2</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas molestie turpis a congue sodales.</p>
          </Col>
        </Row>
        <br /><br /><br /><br />
        <Row className="steps mb-2">
          <Col md={6}>
            <h4>Step 3</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas molestie turpis a congue sodales.</p>
          </Col>
          <Col md={6} className="d-flex">
            <img src="https://res.cloudinary.com/djlafescs/image/upload/v1726450460/Business/PNG/Bar_Char_for_Data_Analysis.png" alt="Step 3" className="img-fluid" />
          </Col>
        </Row>
      </Container>
      <br /><br /><br /><br />
      <hr />
      <Footer></Footer>

    </div>
  );
};

export default HowWeWork;
