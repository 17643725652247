import React, { useState, useEffect, useCallback } from 'react';
import { Form, Col, InputGroup, FormControl, Dropdown, DropdownButton, Button } from 'react-bootstrap';
import { FaSearch, FaTimes } from 'react-icons/fa';
import axios from 'axios';
import { useTheme } from '../themes';
import { useLocation, useNavigate } from 'react-router-dom';

const SearchComponent = ({ onResults, isHomePage }) => {
    const [query, setQuery] = useState('');
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [categories, setCategories] = useState([]);
    const { isDarkTheme } = useTheme();
    const defaultSuggestions = ['bus', 'tickets', 'icon', 'pizza'];
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/categories-tags`);
                setCategories(response.data.categories);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };
        fetchCategories();
    }, []);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const initialQuery = params.get('query') || '';
        const initialCategories = params.get('categories') ? params.get('categories').split(',') : [];

        if (initialQuery || initialCategories.length > 0) {
            setQuery(initialQuery);
            setSelectedCategories(initialCategories);
        }
    }, [location.search]);

    const fetchProducts = useCallback(async () => {
        const safeCategories = Array.isArray(selectedCategories) ? selectedCategories : [];
        const sanitizedQuery = query.replace(/\[.*?\]\s*/g, '').trim();
    
        if (!sanitizedQuery && safeCategories.length === 0) {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/products`);
                onResults(response.data);
            } catch (error) {
                console.error('Error fetching all products:', error);
            }
            return;
        }
    
        if (sanitizedQuery || safeCategories.length > 0) {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/products`, {
                    params: {
                        title: sanitizedQuery,
                        category: safeCategories.join(','),
                    },
                });
    
                if (Array.isArray(response.data)) {
                    onResults(response.data);
                } else {
                    console.error('Expected an array but got:', response.data);
                    onResults([]);
                }
            } catch (error) {
                console.error('Error fetching search results:', error);
            }
        }
    }, [onResults, query, selectedCategories]);

    useEffect(() => {
        fetchProducts();
    }, [fetchProducts]); 

    const handleCategoryChange = (category) => {
        const newSelectedCategories = selectedCategories.includes(category)
            ? selectedCategories.filter(cat => cat !== category)
            : [...selectedCategories, category];

        setSelectedCategories(newSelectedCategories);
        updateQueryWithCategories(newSelectedCategories);
    };

    const updateQueryWithCategories = (categories) => {
        const categoriesString = categories.length > 0 ? `${categories.join(', ')}` : '';
        setQuery(prevQuery => prevQuery.replace(/\[.*?\]\s*/g, '') + (categoriesString ? ` [${categoriesString}] ` : ''));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        handleSearch(query);
    };

    const handleClearFilters = useCallback(() => {
        setQuery('');
        setSelectedCategories([]);
        onResults([]);
    }, [onResults]);

    const handleSearch = useCallback((searchQuery) => {
        const sanitizedQuery = searchQuery.replace(/\[.*?\]\s*/g, '').trim();
        const safeCategories = Array.isArray(selectedCategories) ? selectedCategories : [];
    
        if (sanitizedQuery || safeCategories.length > 0) {
            if (isHomePage) {
                navigate(`/products?query=${encodeURIComponent(sanitizedQuery)}&categories=${encodeURIComponent(safeCategories.join(','))}`);
            } else {
                fetchProducts();
            }
        } else {
            handleClearFilters();
        }
    }, [selectedCategories, isHomePage, navigate, fetchProducts, handleClearFilters]);

    const handleSuggestionClick = (suggestion) => {
        if (query === suggestion) {
            setQuery('');
        } else {
            setQuery(suggestion);
        }
    };

    return (
        <Col xs={12} sm={12} md={8} className='m-auto mt-4'>
            <Form className={`${isDarkTheme ? 'dark-theme' : 'light-theme'}`} onSubmit={handleSubmit}>
                <InputGroup className={`${isDarkTheme ? 'dark-theme' : 'light-theme'}`} style={{ width: '100%', padding: '5px', borderRadius: '7px' }}>
                    <DropdownButton
                        variant="light"
                        title="Advanced Filters"
                        id="category-dropdown"
                        style={{ width: '20%' }}
                        align="start"
                        renderMenuOnMount={true}
                    >
                     {categories && Array.isArray(categories) && categories.length > 0 ? (
                            categories.map(cat => (
                                <Dropdown.Item
                                    key={cat}
                                    active={selectedCategories.includes(cat)}
                                    onClick={() => handleCategoryChange(cat)}
                                >
                                    {cat}
                                </Dropdown.Item>
                            ))
                     ) : (
                            <Dropdown.Item disabled>No categories available</Dropdown.Item>
                        )}

                        <Dropdown.Divider />
                        <Dropdown.Item onClick={handleClearFilters}>
                            <FaTimes /> Clear Filters
                        </Dropdown.Item>
                    </DropdownButton>

                    <FormControl
                        placeholder="Search..."
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        className={`${isDarkTheme ? 'dark-theme' : 'light-theme custom-scrollbar'} `}
                        style={{ border: 'none'}}
                    />
                    <Button variant="primary" type="submit" style={{ width: '130px' }}>
                        Search <FaSearch />
                    </Button>
                </InputGroup>

                <div className="align-items-center mt-3 justify-content-center">
                    <div className="suggestions d-flex justify-content-center">
                        <p style={{ alignSelf: 'center'}}>Ex:</p>
                        {defaultSuggestions.map(suggestion => (
                            <Button
                                key={suggestion}
                                variant={query === suggestion ? "primary" : "outline-secondary"}
                                onClick={() => handleSuggestionClick(suggestion)}
                                className="m-1"
                                style={{ maxWidth: '15%', border: 'none' }}
                            >
                                {suggestion}
                            </Button>
                        ))}
                    </div>

                    {/* Muestra las categorías seleccionadas con una "X" para deseleccionar */}
                    <div className="selected-categories d-flex justify-content-center mt-2">
                        {selectedCategories.map(category => (
                            <div key={category} className="selected-category m-1 d-flex align-items-center">
                                <span>{category}</span>
                                <FaTimes 
                                    onClick={() => handleCategoryChange(category)} 
                                    style={{ marginLeft: '5px', cursor: 'pointer', color: 'red' }} 
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </Form>
            <br />
        </Col>
    );
};

export default SearchComponent;
