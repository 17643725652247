import React from 'react';
import { Navbar, Nav, Button, Container, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { useUser } from '../components/UserContext';
import { auth } from '../utils/firebaseConfig';
//import { IconButton } from '@mui/material';
//import DarkModeIcon from '@mui/icons-material/DarkMode'; // Icono de luna
//import LightModeIcon from '@mui/icons-material/LightMode'; // Icono de sol

const CustomNavbar = ({ toggleTheme, isDarkTheme }) => {
  const user = useUser();

  const handleLogout = async () => {
    await signOut(auth);
  };

  return (
    <Navbar  className={` 'custom-navbar' ${isDarkTheme ? 'dark-theme' : 'light-theme'}`}>
      <Container fluid>
        <br></br>
        <Navbar.Brand as={Link} to="/" ><Image style={{ maxWidth: '120px' }} src={'/logo-cratepic.svg'} alt='logo-cratepic'></Image></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className='justify-content-end'>

          <Nav className='justify-content-end flex-grow-1'>
          <Nav>
            <Nav.Link as={Link} to="/pricing" className="custom-nav-link">Pricing</Nav.Link>
            
          </Nav>
            {user ? (
              <>
                <Nav.Link as={Link} to="/user" className="custom-nav-link">{user.displayName || user.email}</Nav.Link>
                <Col md={1}>
                  <Button variant='secondary' className={` ${isDarkTheme ? 'dark-theme' : 'light-theme'}`} onClick={handleLogout}>Logout</Button>
                </Col>
              </>
            ) : (
              <Col md={1} lg={2} className='d-flex' >
                <Button as={Link} to="/login" variant='primary' className={` ${isDarkTheme ? 'dark-theme' : 'light-theme'}`}  style={{maxWidth:"150px", marginRight:'10px'}}>Login</Button>
                <Button as={Link} to="/register" variant='secondary'  className={` ${isDarkTheme ? 'dark-theme' : 'light-theme'}`} style={{maxWidth:"150px"}}>Register</Button>

              </Col>
            )}
              {/*  <IconButton onClick={toggleTheme} color="inherit">
              {isDarkTheme ? <LightModeIcon /> : <DarkModeIcon />}
            </IconButton>*/}
          </Nav>
          
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default CustomNavbar;
