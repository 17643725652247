import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useUser } from './UserContext';

const ProtectedRoute = ({ element: Component, ...rest }) => {
  const user = useUser();
  const location = useLocation();
  return user ? (
    <Navigate to={location.state?.from || "/"} replace />
  ) : (
    <Component {...rest} />
  );
};

export default ProtectedRoute;
