import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import Payment from './components/Payment';
import ProductCard from './components/ProductCard';
import Register from './components/Register';
import User from './pages/User';
import CustomNavbar from './components/NavBar';
import ProductList from './pages/ProductList';
import ProductDetail from './pages/ProductDetail';
import { ThemeProvider, useTheme } from './themes';
import { UserProvider } from './components/UserContext';
import PrivateRoute from './components/PrivateRoute';
import ProtectedRoute from './components/ProtectedRoute';
import ResetPassword from './components/RestablecimientoPass';

import 'react-toastify/dist/ReactToastify.css';
import Pricing from './pages/Pricing';
import HowWeWork from './pages/Custom';
import WhatsNew from './pages/WhatsNews';
import AboutUs from './pages/About'
import HomePage from './pages/HomePage';
import ThankYouPage from './pages/ThankyouPage';




import { ToastContainer } from 'react-toastify';


const AppContent = () => {
  const { isDarkTheme, toggleTheme } = useTheme();

  return (

    <>
    <body  className ={isDarkTheme ? 'dark-theme' : 'light-theme'}>
    <div>
      <CustomNavbar toggleTheme={toggleTheme} isDarkTheme={isDarkTheme} />
      <ToastContainer />
    <Routes >
        <Route path="/login" element={<ProtectedRoute element={Login} />} />
        <Route path="/register" element={<ProtectedRoute element={Register} />} />
        <Route path="/user" element={<PrivateRoute element={User} />} />
        <Route path="/products" element={<ProductList />} />
        <Route path="/product/:id" element={<ProductDetail />} />
        <Route path="/payment/:id" element={<PrivateRoute element={Payment} />} />
        <Route path="/productcard" element={<PrivateRoute element={ProductCard} />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/custom" element={<HowWeWork />} />
        <Route path="/whats-new" element={<WhatsNew />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/thankyoupage" element={<ThankYouPage />} />

        <Route path="/" element={<HomePage />} />
      </Routes>
    </div>
    </body>
    </>

  );
};

const App = () => {
  return (
    <ThemeProvider>
      <Router>
        <UserProvider>
          <AppContent />
        </UserProvider>
      </Router>
    </ThemeProvider>
  );
};

export default App;
