import React, { useState } from 'react';
import { Form, Button, Toast, Col, Row, Container } from 'react-bootstrap';
import axios from 'axios';
import { useTheme } from '../themes';

const SubscriptionForm = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [statusMessage, setStatusMessage] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const { isDarkTheme } = useTheme();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/subscribe`, { email, name });
      setStatusMessage({ type: 'success', text: response.data.message });
      setIsSubscribed(true);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setIsSubscribed(true);
        setStatusMessage({ type: 'warning', text: 'Ya estás suscrito' });
      } else {
        setStatusMessage({ type: 'danger', text: 'Ocurrió un error, intenta de nuevo' });
      }
    }
  };

  return (
    <>
      {statusMessage && (
        <Toast show={true} onClose={() => setStatusMessage(null)} autohide delay={3000}>
          <Toast.Body>{statusMessage.text}</Toast.Body>
        </Toast>
      )}
      <Container className="my-5 pb-5">

                <Row className="steps mb-2">
          <Col md={6} className='center-align'>
              <h3>Stay Inspired with Our Latest Design Resources</h3>
              <p>Subscribe to our newsletter for updates on new illustrations, exclusive resources, and design tips—delivered straight to your inbox.</p>
              <img  src="https://res.cloudinary.com/dcewps9z7/image/upload/v1730530543/templates/public/asset-home-03_wqpahy.png" alt="Step 1" className="img-fluid m-auto pb-5" />

            </Col>
            <Col md={6} >
            
      {!isSubscribed && (
        
        <Form onSubmit={handleSubmit} className={` ${isDarkTheme ? 'dark-theme' : 'light-theme' }  form-suscriber`} style={{ width: '100%', padding: '10%', borderRadius: '7px' }}>
          <Form.Label>Email</Form.Label>
          
          <Form.Control
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className={`${isDarkTheme ? 'dark-theme' : 'light-theme'}`}

          />
          <Form.Label className='pt-3'>Name</Form.Label>

          <Form.Control
            type="text"
            placeholder="Enter your name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className={`${isDarkTheme ? 'dark-theme' : 'light-theme'} `}

          />
          <div className='pt-5'>
          <Button variant="primary" type="submit">
            Subscribe
          </Button>
          </div>
        </Form>
      )}
            </Col>
            <br></br>
          </Row>
          <br></br>

      </Container>
    
    </>
  );
};

export default SubscriptionForm;
