import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Row, Col, Card, Button, Breadcrumb, Spinner } from 'react-bootstrap';
import Footer from '../components/Footer';

const ThankYouPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [purchaseData, setPurchaseData] = useState(null);

  useEffect(() => {
    const userData = location.state;  // Datos pasados desde el pago exitoso
    if (!userData) {
      navigate('/');
      return;
    }
    setPurchaseData(userData);
    setLoading(false);
  }, [location.state, navigate]);

  if (loading) return (
    <Container className="mt-5 mb-5" style={{ maxWidth: '768px', textAlign: 'center' }}>
      <Spinner animation="border" variant="primary" />
      <p>Loading purchase details...</p>
    </Container>
  );

  return (
    <>
      <Container className="mt-5 mb-5" style={{ maxWidth: '768px' }}>
        <Breadcrumb>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Thank You</Breadcrumb.Item>
        </Breadcrumb>

        <Row>
          <Col>
            <Card className="p-4 custom-card">
              <Card.Header className="text-center">
                <h2>Thank You for Your Purchase!</h2>
              </Card.Header>
              <Card.Body>
                <h3>Order Summary</h3>
                <Card.Text><strong>Product Name:</strong> {purchaseData?.title}</Card.Text>
                <Card.Text><strong>Description:</strong> {purchaseData?.description}</Card.Text>
                <Card.Text><strong>Price:</strong> ${purchaseData?.price}</Card.Text>
                <Card.Text><strong>Purchase Date:</strong> {new Date().toLocaleDateString()}</Card.Text>
                <Card.Text><strong>Buyer Email:</strong> {purchaseData?.buyerEmail}</Card.Text>

                <hr />
                <h4>Download Your Purchase</h4>
                <Button
                  href={purchaseData?.downloadUrl}
                  variant="primary"
                  className="custom-button"
                  download
                >
                  Download {purchaseData?.title}
                </Button>

                <hr />
                <h5>Terms of Use</h5>
                <p>
                  By downloading this product, you agree to our <a href="/terms">Terms of Use</a> and <a href="/privacy">Privacy Policy</a>. 
                  Please make sure to adhere to our licensing agreement for personal and commercial use.
                </p>

                <Button onClick={() => navigate('/products')} variant="secondary">
                  Back to Shop
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default ThankYouPage;
