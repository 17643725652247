import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { auth } from '../utils/firebaseConfig';
import { toast } from 'react-toastify';
import { Card, Button, Tooltip, OverlayTrigger, Col } from 'react-bootstrap';
import Favorite from '@mui/icons-material/Favorite';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import GetApp from '@mui/icons-material/GetApp';
import Visibility from '@mui/icons-material/Visibility';
import { useTheme } from '../themes';

const ProductCard = ({ product }) => {

  const [likes, setLikes] = useState(product.likes);
  const [visits, setVisits] = useState(product.visits);
  const [isLiked, setIsLiked] = useState(false);
  const [hasSubscription, setHasSubscription] = useState(false);
  const navigate = useNavigate();
  const { isDarkTheme } = useTheme();

  useEffect(() => {
    const fetchUserLikes = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const token = await user.getIdToken();
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const userData = response.data;
          const likedProduct = userData.likes.some(like => like._id === product._id);
          setIsLiked(likedProduct);
          setHasSubscription(userData.hasSubscription);
        } catch (error) {
          console.error('Error checking like status:', error);
        }
      }
    };

    fetchUserLikes();
  }, [product._id]);

  const handleLikeToggle = async (event) => {
    event.stopPropagation();
    const user = auth.currentUser;
    if (!user) {
      navigate(`/login?redirect=${window.location.pathname}`);
      return;
    }

    try {
      const token = await user.getIdToken();
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/${product._id}/like`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { isLiked: updatedIsLiked, likesCount } = response.data;

      setLikes(likesCount);
      setIsLiked(updatedIsLiked);
    } catch (error) {
      console.error('Error liking/unliking product:', error);
      toast.error('Error updating like status.');
    }
  };

  const handleDownload = async (format) => {
    const user = auth.currentUser;
    if (!user) {
      toast.error('You need to be logged in to download products.');
      return;
    }

    try {
      const token = await user.getIdToken();
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/${product._id}/download`,
        { format },
        { headers: { Authorization: `Bearer ${token}` }, responseType: 'blob' }
      );

      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const link = document.createElement('a');
      const url = window.URL.createObjectURL(blob);
      link.href = url;
      link.setAttribute('download', `Halloween_-_Vampire.${format}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading product:', error.message);
      toast.error('Failed to download product.');
    }
  };

  const handleViewDetails = async () => {
    const user = auth.currentUser;
    const token = user ? await user.getIdToken() : null;

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/${product._id}/visit`, {}, {
        headers: {
          Authorization: token ? `Bearer ${token}` : '',
        },
      });
      const { visitsCount } = response.data;
      setVisits(visitsCount);
    } catch (error) {
      console.error('Error updating visits:', error);
    }

    navigate(`/product/${product._id}`);
  };

  const formatNumber = (num) => {
    if (typeof num !== 'number') {
      return '0';
    }
    if (num >= 1000000000) {
      return (num / 1000000000).toFixed(num % 1000000000 === 0 ? 0 : 1) + 'B';
    } else if (num >= 1000000) {
      return (num / 1000000).toFixed(num % 1000000 === 0 ? 0 : 1) + 'M';
    } else if (num >= 1000) {
      return (num / 1000).toFixed(0) + 'K';
    } else {
      return num.toString();
    }
  };

  return (
    <Col className="mb-4" onClick={handleViewDetails}>
      <Card className={`h-100 ${isDarkTheme ? 'dark-theme' : 'light-theme'}`}>
        <Card.Img variant="top" src={product.publicDownloadUrl} alt={product.title} />
        <Card.Body>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{product.title}</Tooltip>}
          >
            <h5 className="text-truncate">{product.title}</h5>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{product.description}</Tooltip>}
          >
            <Card.Text className="text-truncate" style={{ maxHeight: '2.4em', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {product.description}
            </Card.Text>
          </OverlayTrigger>

          <div className="d-flex justify-content-start">
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>{isLiked ? 'Unlike' : 'Like'}</Tooltip>}
            >
              <Button variant="" onClick={handleLikeToggle}>
                {isLiked ? <Favorite /> : <FavoriteBorder />}
                <span className="ms-2">{formatNumber(likes)}</span>
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Visitas</Tooltip>}
            >
              <Button variant="" onClick={handleViewDetails}>
                <Visibility /> <span className="ms-2">{formatNumber(visits)}</span>
              </Button>
            </OverlayTrigger>

            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Download PNG</Tooltip>}
            >
              <Button variant="" onClick={() => handleDownload('png')}>
                <GetApp /> PNG
              </Button>
            </OverlayTrigger>

            {hasSubscription && (
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Download SVG</Tooltip>}
              >
                <Button variant="" onClick={() => handleDownload('svg')}>
                  <GetApp /> SVG
                </Button>
              </OverlayTrigger>
            )}
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default ProductCard;
