import React from 'react';
import { Container, Carousel } from 'react-bootstrap';
import Footer from '../components/Footer';

const AboutUs = () => {
  return (
    <div className="plans-container" >
      <Container className="text-center py-3 pt-md-5 pb-md-4" >
        <h1>About us</h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis hendrerit ac ex eget maximus. Etiam tempus mi quis ornare facilisis. Integer sed urna ac libero accumsan hendrerit. Etiam orci eros, dapibus a lobortis consectetur, posuere sit amet dui. Ut et nisl commodo metus convallis hendrerit. Duis sed dui cursus, hendrerit orci ac, tincidunt ante. Integer in nibh sapien. Mauris efficitur elit ut felis pharetra, elementum pharetra elit pharetra. Donec ut leo hendrerit, sagittis mi sit amet, efficitur lacus. Etiam a eros quam. Maecenas eros enim, molestie et mollis ultrices, posuere quis eros. Sed tincidunt risus at vestibulum consectetur. In tempor nisl tincidunt sem tempus ultrices. Donec a odio elit. Etiam sed vehicula elit. Morbi in tincidunt sem. Mauris ac lorem quis risus venenatis faucibus id vitae tortor. Proin malesuada purus id venenatis molestie. Vivamus a sodales eros. Maecenas dignissim augue id urna molestie tempus. Ut non nulla eget sem efficitur mollis scelerisque eu dolor. Suspendisse eu congue nulla, at egestas augue.
        </p>

        <Carousel id="carouselExampleControls" className="my-4">
          <Carousel.Item>
            <img className="d-block w-100" src="img/img01.png" alt="First slide" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src="img/img01.png" alt="Second slide" />
          </Carousel.Item>
        </Carousel>
      </Container>

      <br /><br /><br /><br />
      <hr />
      <Footer style={{bottom: '0'}}></Footer>
    </div>
  );
};

export default AboutUs;
