import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { FaInstagram, FaDribbble, FaBehance } from 'react-icons/fa';
import { useTheme } from '../themes';

const Footer = () => {
  const { isDarkTheme } = useTheme();
  return (
    <footer className="  footeer">
      <hr></hr>

      <Container className ={isDarkTheme ? 'dark-theme' : 'light-theme'}>

        <Row className='py-5' >
        <Col md={4} className=" ">
        <Image style={{ maxWidth: '120px' }} src={'/logo-cratepic.svg'} alt='logo-cratepic'></Image>
        <br></br>
        <p className='pt-3'>Digital assets for teams: Curated Icons, illustrations, Email and Web Templates.</p>
        <br></br>

        <Image style={{ maxWidth: '300px' }} src={'https://res.cloudinary.com/dcewps9z7/image/upload/v1730586597/samples/secure-payu_n332vv.png'} alt='logo-cratepic'></Image>
        <br></br>
        <p style={{fontSize:'12px'}} className='pt-3'>© Cratepic Inc. All rights reserved. <a href="#" target="_blank" rel="noopener noreferrer" className="text-white mx-2">Terms of Service Privacy Policy</a></p>
        <br></br>

        </Col>
          <Col className='d-grid p-2 ' style={{alignSelf:'start', justifyContent:'center'}} >
            <a href="/pricing" target="_blank" rel="noopener noreferrer" className=" mx-2 my-2">
            Pricing Page
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer" className=" mx-2 my-2">
            Custom Projects
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer" className=" mx-2 my-2">
            About Us
            </a>
          </Col>
          <Col style={{alignSelf:'start'}}>
        <h5 className='pt-3'>Social Media</h5>

            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className=" mx-2">
              <FaInstagram size={24} />
            </a>
            <a href="https://dribbble.com" target="_blank" rel="noopener noreferrer" className=" mx-2">
              <FaDribbble size={24} />
            </a>
            <a href="https://behance.net" target="_blank" rel="noopener noreferrer" className=" mx-2">
              <FaBehance size={24} />
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
