import React, { useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../utils/firebaseConfig';
import { Form, Button, Container, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Se ha enviado un enlace de restablecimiento a tu correo.');
    } catch (error) {
      setError('No se pudo enviar el enlace de restablecimiento.');
    }
  };

  return (
    <Container  style={{ maxWidth: '445px' }} >
      <h2 className="text-center my-4">Restablecer Contraseña</h2>
      <b></b>
      <hr></hr>
      <br></br>
      {message && <Alert variant="success">{message}</Alert>}
      {error && <Alert variant="danger">{error}</Alert>}
      <Form onSubmit={handleResetPassword}>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>
        <Button variant="primary" type="submit" className="mt-3">
          Restablecer Contraseña
        </Button>
      </Form>
      <p className="mt-3">
        <Link to="/login">Volver al login</Link>
      </p>
    </Container>
  );
};

export default ResetPassword;
