import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Row, Col, Button, Container, Card } from 'react-bootstrap';
import axios from 'axios';
import { useTheme } from '../themes';
import Footer from '../components/Footer';
import SubscriptionForm from '../components/Suscriptors';
import Search from '../components/Search';

const HomePage = () => {
  const navigate = useNavigate();
  const { isDarkTheme } = useTheme();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchLatestProducts = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/product-recents`);
      
        // Check if the response is valid and in JSON format
        if (response.headers['content-type'] && response.headers['content-type'].includes('application/json')) {
          if (response.data && Array.isArray(response.data)) {
            const limitedProducts = response.data.slice(0, 8);
            setProducts(limitedProducts);
          } else {
            console.warn('Unexpected data structure:', response.data);
          }
        } else {
          console.error('Unexpected response type, not JSON:', response);
        }
      } catch (error) {
        console.error('Error fetching latest products:', error.response || error.message);
      }
       finally {
        setLoading(false);
      }
    };
  
    fetchLatestProducts();
  }, []);
  

  const handleSearch = useCallback(async (searchTerm) => {
    // Asegúrate de que searchTerm es una cadena
    if (typeof searchTerm === 'string' && searchTerm.trim() !== '') {
        try {
            const response = await fetch(`/api/products?query=${encodeURIComponent(searchTerm)}`);
            const data = await response.json();
            setProducts(data);
        } catch (error) {
            console.error('Error fetching search results:', error);
        }
    } // Si searchTerm no es una cadena válida o está vacía, simplemente no haces nada
}, []);


  const handleExploreClick = () => {
    navigate(`/products`);
  };

  // Redirigir al detalle de la ilustración
  const handleProductClick = (productId) => {
    navigate(`/product/${productId}`);
  };

  return (
    <>
      <Container fluid="lg" className={`p-5 bg-header-home ${isDarkTheme ? 'dark-theme' : 'light-theme'}`} style={{ textAlign: 'center', paddingBottom:"-100px" }}>
        <Col xs={12} md={12} className='m-auto'>
          <h1 className="my-4 text-center">Download free premium resources for your digital product</h1>
        </Col>
        <Col md={8} className='m-auto'>
          <Search onResults={handleSearch} isHomePage={true} />
        </Col>
      </Container>
        {/* Sección de ilustraciones destacadas */}
        <Container fluid="lg" className='pt-2'>
          <Col xs={12} className='m-auto'>
            <h2 className="text-center mb-4">New illustrations ⚡</h2>
            <Row className='m-auto' style={{ maxWidth: '70%' }}>
              {loading ? (
                <Col className="text-center">Loading...</Col>
              ) : (
                products.length > 0 ? products.map((product, index) => (
                  <Col xs={12} sm={6} md={3} key={index} className="mb-4">
                    <Card className='h-100' onClick={() => handleProductClick(product._id)}>
                      <Card.Img
                        className='fluid'
                        variant="top"
                        src={product.publicDownloadUrl} alt={product.title} 
                      />
                    </Card>
                  </Col>
                )) : <Col className="text-center">No products found.</Col>
              )}
            </Row>
            <Col className='m-auto text-center'>
              <Button style={{ maxWidth: '180px' }} variant="primary" onClick={handleExploreClick} className="mt-4">
                Show More
              </Button>
            </Col>
          </Col>
        </Container>

      <br /><br /><br />

      {/* Exploración custom */}
      <Container fluid="lg" className="my-5 pb-5 m-auto">
      <br></br>

        <Col xs={12} md={10} className='m-auto'>
          <Row className="steps mb-2">
            <Col md={5} className='center-align'>
              <h3>Custom Illustrations for your project?</h3>
              <p>Bring your ideas to life with unique, custom-made illustrations. Tailored to perfectly fit the style and vision of your project.</p>
              <Button as={Link} to="/pricing" style={{ maxWidth: '180px' }} className="custom-dropdown-item my-4">Read more</Button>
            </Col>
            <Col md={7} className="d-flex ">
              <img  src="https://res.cloudinary.com/dcewps9z7/image/upload/v1730530543/templates/public/asset-home-01_bdswgh.png" alt="Step 1" className="pt-5 img-fluid m-auto" />
            </Col>
          </Row>
        </Col>
      </Container>

      {/* Exploración custom */}
      <section style={{backgroundColor:'#ffffff'}}>
        <br></br>
      <Container fluid="lg" className="my-5 pb-5 m-auto" >
        
        <Col xs={12} md={10} className='m-auto'>
        
          <Row className="steps mb-2">
          <Col md={7} className="d-flex ">
              <img  src="https://res.cloudinary.com/dcewps9z7/image/upload/v1730530543/templates/public/asset-home-02_rsctke.png" alt="Step 1" className="pb-5 img-fluid m-auto" />
            </Col>
            <Col md={5} className='center-align'>
              <h3>Access High-Quality Illustrations and Resources for Free</h3>
              <p>Sign up now to start downloading premium illustrations, vectors, and icons—no cost, no hassle.</p>
              <Button as={Link} to="/pricing" style={{ maxWidth: '180px' }} className="custom-dropdown-item my-4">Sign up Free</Button>
            </Col>

          </Row>
        </Col>
      </Container>


      </section>


      {/* Formulario de subscripción */}
      <Container className="my-5 pb-5">
        <Col xs={12} md={10} className='m-auto'>
        <br></br>
          <SubscriptionForm />
        </Col>
      </Container>
      <Footer />
    </>
  );
};

export default HomePage;
