import React, { useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';
import { auth } from '../utils/firebaseConfig';
import { Container, Row, Col, ListGroup, Card, Button, Form, Spinner } from 'react-bootstrap';
import { Favorite } from '@mui/icons-material';
import { useTheme } from '../themes';
import Footer from '../components/Footer';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const User = () => {
  const { id } = useParams();

  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeSection, setActiveSection] = useState('likes');
  const { isDarkTheme } = useTheme();

  const UserSettings = ({ userData }) => {
    const [displayName, setDisplayName] = useState(userData?.displayName || '');
    const [email, setEmail] = useState(userData?.email || '');

    return (
      <div>
        <h2>Settings</h2>
        <p>Ajustes de la Cuenta</p>
        <hr />
        <Form className={`' p-4 ' ${isDarkTheme ? 'dark-theme' : 'light-theme'}`}>
          {/* Nombre */}
          <Form.Group controlId="formDisplayName">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type="text"
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
              placeholder="Introduce tu nombre"
              className={`${isDarkTheme ? 'dark-theme' : 'light-theme'}`}
            />
          </Form.Group>
          <hr />
          {/* Correo Electrónico */}
          <Form.Group controlId="formEmail">
            <Form.Label>Correo Electrónico</Form.Label>
            <Form.Control
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Introduce tu correo electrónico"
              className={` ${isDarkTheme ? 'dark-theme' : 'light-theme'}`}
            />
          </Form.Group>
          <hr />
          {/* Botón para Actualizar Perfil */}
          <Button variant="primary" type="submit">
            Actualizar Perfil
          </Button>
        </Form>
      </div>
    );
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const token = await user.getIdToken();
          const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const data = await response.json();
          setUserData(data);
        } catch (error) {
          console.error('Error fetching user data:', error);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchUserData();
  }, []);

  const handleUnlike = async (productId) => {
    const user = auth.currentUser;
    if (!user) {
      alert("You need to be logged in to unlike products.");
      return;
    }

    try {
      const token = await user.getIdToken();
      await fetch(`${process.env.REACT_APP_API_URL}/api/${productId}/like`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ productId })
      });

      setUserData(prevData => ({
        ...prevData,
        likes: prevData.likes.filter(product => product._id !== productId)
      }));
    } catch (error) {
      console.error('Error unliking product:', error);
    }
  };
  const handleDownload = async (product, format) => {
    const user = auth.currentUser;
    if (!user) {
        toast.error('You need to be logged in to download products.');
        return;
    }

    try {
        const token = await user.getIdToken();
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/${product._id}/download`,
            { format },
            { headers: { Authorization: `Bearer ${token}` }, responseType: 'blob' }
        );

        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        
        // Generate a valid filename using the product title
        const safeTitle = product.title.replace(/[^a-z0-9_.-]/gi, '_').toLowerCase(); // Replace unsafe characters
        link.setAttribute('download', `${safeTitle}.${format}`); // Use the product title for the filename
        
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Error downloading product:', error.message);
        toast.error('Failed to download product.');
    }
};

const renderDownloadLinks = () => {
    if (!userData || !userData.downloads || userData.downloads.length === 0) {
        return <p>No tienes productos descargados.</p>;
    }

    return (
        <ListGroup>
            <h2>Historial de Descargas</h2>
            <p>Tus últimas descargas</p>
            <hr />
            {userData.downloads.map((product) => {
                const hasPrivateAccess = userData.permissions.includes(`access_${product._id}`) || userData.permissions.includes('access_all');
                const hasActiveSubscription = userData.activeSubscription; // Verificar si hay una suscripción activa

                return (
                    <ListGroup.Item key={product._id} className={` ${isDarkTheme ? 'dark-theme' : 'light-theme'}`}>
                        <p>{product.title}</p>
                        {hasPrivateAccess && product.privateDownloadUrl && (
                            <>
                                <Button variant="link" onClick={() => handleDownload(product, 'png')}>
                                    Descarga PNG
                                </Button>
                                {hasActiveSubscription && (
                                    <>
                                        {' | '}
                                        <Button variant="link" onClick={() => handleDownload(product, 'svg')}>
                                            Descarga SVG
                                        </Button>
                                    </>
                                )}
                            </>
                        )}
                    </ListGroup.Item>
                );
            })}
        </ListGroup>
    );
};


  const renderPurchaseHistory = () => {
    if (!userData || !userData.purchaseHistory || userData.purchaseHistory.length === 0) {
      return <p>No tienes historial de compras.</p>;
    }

    return (
      <ListGroup>
        <h2>Historial de Compras</h2>
        <p>Tus últimas adquisiciones</p>
        <hr />
        {userData.purchaseHistory.map((purchase) => {
          const purchaseId = purchase._id;
          const itemId = userData?.activeSubscription?.plan?.description || 'Sin plan'; // Verifica si el plan existe

          return (
            <ListGroup.Item key={purchaseId} className={`${isDarkTheme ? 'dark-theme' : 'light-theme'}`}>
              <p>
                {formatPurchaseDate(purchase.date)}
                {' - '}
                {itemId}
                {' - '}
                ${purchase.amount.toFixed(2)}
              </p>
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    );
  };

  const renderActiveSubscription = () => {
    const activeSubscription = userData?.activeSubscription;
    if (!activeSubscription || !activeSubscription.plan) {
      return <p>No tienes una suscripción activa.</p>;
    }

    const planDescription = activeSubscription.plan?.description || 'Descripción no disponible';

    return (
      <>
        <h2>Suscripción Activa</h2>
        <p>Tus últimas suscripciones</p>
        <hr />
        <p>
          Sí, tienes una suscripción activa ({planDescription}) hasta el{' '}
          {format(parseISO(activeSubscription.endDate), 'd MMMM yyyy', { locale: es })}.
        </p>
      </>
    );
  };

  const renderLikes = () => {
    if (!userData || !userData.likes || userData.likes.length === 0) {
      return <p>No tienes productos en tu lista de favoritos.</p>;
    }
    return (
      <ListGroup>
        <h2>Likes</h2>
        <p>Your favorite products</p>
        <hr />
        {userData.likes.map((product) => (
          <ListGroup.Item key={product._id} className={` ${isDarkTheme ? 'dark-theme' : 'light-theme'}`}>
            <Row>
              <Col>
                <p>{product.title}</p>
              </Col>
              <Col md={2} style={{ textAlign: 'right' }}>
                <Button variant="m-1 float-right" onClick={() => handleUnlike(product._id)}>
                  <Favorite />
                </Button>
              </Col>
            </Row>
          </ListGroup.Item>
        ))}
      </ListGroup>
    );
  };

  const formatPurchaseDate = (date) => {
    const parsedDate = parseISO(date);
    return format(parsedDate, 'MMMM yyyy', { locale: es });
  };

  const renderActiveSection = () => {
    switch (activeSection) {
      case 'likes':
        return renderLikes();
      case 'purchase-history':
        return renderPurchaseHistory();
      case 'downloads':
        return renderDownloadLinks();
      case 'subscription':
        return renderActiveSubscription();
      case 'settings':
        return <UserSettings userData={userData} />;
      default:
        return null;
    }
  };

  if (loading) {
    return (
      <Container>
        <Spinner animation="border" variant="primary" />
      </Container>
    );
  }

  if (!userData) {
    return <p>No se encontraron datos del usuario.</p>;
  }

  return (
    <>
      <Container fluid className='pt-5 pb-5' style={{ maxWidth: '992px' }}>
        <h1>Profile</h1>
        <p>All your things here</p>
        <hr />
        <br />
        <Row>
          <Col md={3} className={`' sidebar' ${isDarkTheme ? 'dark-theme' : 'light-theme'}`}>
            <ListGroup variant="flush">
              <ListGroup.Item action onClick={() => setActiveSection('likes')} active={activeSection === 'likes'}>
                <p>Likes</p>
              </ListGroup.Item>
              <ListGroup.Item action onClick={() => setActiveSection('purchase-history')} active={activeSection === 'purchase-history'}>
                <p>Historial de Compras</p>
              </ListGroup.Item>
              <ListGroup.Item action onClick={() => setActiveSection('downloads')} active={activeSection === 'downloads'}>
                <p>Productos Descargados</p>
              </ListGroup.Item>
              <ListGroup.Item action onClick={() => setActiveSection('subscription')} active={activeSection === 'subscription'}>
                <p>Suscripción Activa</p>
              </ListGroup.Item>
              <ListGroup.Item action onClick={() => setActiveSection('settings')} active={activeSection === 'settings'}>
                <p>Settings</p>
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col md={9} fluid="sm">
            <Card className={`' mb-4 ' ${isDarkTheme ? 'dark-theme' : 'light-theme'}`}>
              <Card.Body>
                {renderActiveSection()}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default User;
