import React, { useEffect, useState, useCallback } from 'react';
import ProductCard from '../components/ProductCard';
import axios from 'axios';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import Search from '../components/Search';
import SubscriptionForm from '../components/Suscriptors';
import Footer from '../components/Footer';
import { useLocation } from 'react-router-dom';

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const location = useLocation();

  const fetchProducts = async (query, categories) => {
    setLoading(true);
    setNoResults(false); // Reiniciar noResults al iniciar la carga
  
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/products`, {
        params: {
          title: query || undefined,
          category: categories.length > 0 ? categories.join(',') : undefined,
        },
      });
  
      // Ordenar productos por createdAt (suponiendo que existe este campo)
      const sortedProducts = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setProducts(sortedProducts);
      setNoResults(sortedProducts.length === 0);
    } catch (error) {
      console.error('Error fetching products:', error);
      setNoResults(true); // Mostrar noResults en caso de error
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const query = queryParams.get('query') || '';
    const categories = queryParams.get('categories') ? queryParams.get('categories').split(',') : [];
    fetchProducts(query, categories);
  }, [location.search]);

  const handleSearchResults = useCallback((results) => {
    if (Array.isArray(results)) {
        // Ordenar productos por createdAt al recibir resultados de búsqueda
        const sortedResults = results.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setProducts(sortedResults);
        setNoResults(sortedResults.length === 0);
    } else {
        console.error('Expected an array but got:', results);
        setProducts([]); // Manejar el caso donde no es un array
        setNoResults(true); // Mostrar noResults si no hay productos
    }
}, []);

  return (
    <>
      <Container>
        <Search onResults={handleSearchResults} isHomePage={false} />
        <Row className="my-5">
          {loading ? (
            <Col className="text-center">
              <Spinner animation="border" />
            </Col>
          ) : noResults ? (
            <Col className="text-center">No results found.</Col>
          ) : (
            products.map((product, index) => (
              <Col key={index} md={3} className="mb-4">
                <ProductCard product={product} />
              </Col>
            ))
          )}
        </Row>

        <Row className="pb-5">
          <SubscriptionForm />
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default ProductList;
